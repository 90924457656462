import React, { FC } from 'react'
import ParentModal from './ParentModal'
import { FaTimes } from 'react-icons/fa'

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose?: () => void
  title?: string
  children: React.ReactNode
}

const Modal: FC<ModalProps> = ({ title, children, onClose, ...otherProps }) => {
  return (
    <ParentModal>
      <div
        className="relative w-[90%] max-w-[540px] flex flex-row items-start justify-between px-[44px] pt-4 pb-6 bg-white rounded-xl"
        {...otherProps}
      >
        <div className="flex flex-col gap-1 items-center justify-center w-full pt-6">
          {title && (
            <div className="max-w-[320px] text-center">
              <p className="text-xl text-center font-bold">{title}</p>
            </div>
          )}
          <div className="flex flex-col items-center pt-[20px] gap-2 w-full md:flex-col md:w-full">
            {children}
          </div>
        </div>
        {onClose && (
          <div className="absolute right-3 flex items-center justify-center w-8 h-8">
            <div onClick={onClose}>
              <FaTimes style={{ fontSize: 20 }} />
            </div>
          </div>
        )}
      </div>
    </ParentModal>
  )
}

export default Modal
