import Home from '@/pages/home'
import { APP_ROUTES } from '../constants/app-routes'
import React from 'react'
import {
  About,
  Activity,
  Dashboard,
  ForgotPassword,
  Invoice,
  Login,
  More,
  NewOrder,
  Notifications,
  PasswordResetSuccess,
  Profile,
  RegisterOne,
  RegisterTwo,
  ResetPassword,
  VerifyOtp,
  Welcome,
  BulkOrder,
  OrderDetails,
  Tracking,
  SwitchAccount,
  PendingBulkOrder,
} from '@/pages'

export const routes: { path: string; element: React.FC }[] = [
  {
    path: APP_ROUTES.HOME,
    element: Home,
  },
  {
    path: APP_ROUTES.ABOUT,
    element: About,
  },
  { path: APP_ROUTES.SWITCH_ACCOUNT, element: SwitchAccount },
]

export const authRoutes: { path: string; element: React.FC }[] = [
  {
    path: APP_ROUTES.LOGIN,
    element: Login,
  },
  {
    path: APP_ROUTES.CREATE_ACCOUNT,
    element: RegisterOne,
  },
  {
    path: APP_ROUTES.CREATE_ACCOUNT2,
    element: RegisterTwo,
  },
  {
    path: APP_ROUTES.VERIFY_OTP,
    element: VerifyOtp,
  },
  {
    path: APP_ROUTES.FORGOT_PASSWORD,
    element: ForgotPassword,
  },
  {
    path: APP_ROUTES.RESET_PASSWORD,
    element: ResetPassword,
  },
  {
    path: APP_ROUTES.PASSWORD_RESET_SUCCESS,
    element: PasswordResetSuccess,
  },
  { path: APP_ROUTES.WELCOME, element: Welcome },
]

export const privateRoute: { path: string; element: React.FC }[] = [
  { path: APP_ROUTES.DASHBOARD, element: Dashboard },
  { path: APP_ROUTES.NOTIFICATIONS, element: Notifications },
  { path: APP_ROUTES.REQUEST_RIDER, element: NewOrder },
  { path: APP_ROUTES.PROFILE, element: Profile },
  { path: APP_ROUTES.ACTIVITY, element: Activity },
  { path: APP_ROUTES.BULK_ORDER, element: BulkOrder },
  { path: APP_ROUTES.PENDING_BULK_ORDER, element: PendingBulkOrder },
  { path: APP_ROUTES.MORE, element: More },
  { path: APP_ROUTES.INVOICE, element: Invoice },
  { path: APP_ROUTES.ORDER_DETAILS, element: OrderDetails },
  { path: APP_ROUTES.TRACK, element: Tracking },
  // { path: APP_ROUTES.SWITCH_ACCOUNT, element: SwitchAccount },
]
