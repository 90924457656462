import { countryData } from "@/constants/country";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import {
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BiChevronDown } from "react-icons/bi";
import Label from "../Label/Label";
import { formatPhoneNum } from "@/utils";

interface IInputPhone extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  isRequired?: boolean;
  setSelectedCode: React.Dispatch<React.SetStateAction<string>>;
  selectedCode: string;
  setValue: any;
}

const InputPhone = forwardRef<HTMLInputElement, IInputPhone>(
  (
    {
      label,
      isRequired,
      placeholder,
      className,
      setSelectedCode,
      selectedCode,
      error,
      value,
      onChange,
      setValue,
      name,
      ...rest
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const dropdownRef = useOutsideClick(() => setOpen(false));

    const toggleDropdown = () => {
      setOpen(!open);
    };

    const handleClick = (code: string) => {
      setSelectedCode(code);
      toggleDropdown();
      setSearch("");
    };

    const countryPhoneCode = useMemo(() => {
      if (countryData) {
        if (!search) return countryData;
        return countryData.filter((code) =>
          code.name.toLowerCase().includes(search.toLowerCase())
        );
      }
      return [];
    }, [search, countryData]);

    useEffect(() => {
      if (value) {
        const formattedPhone = formatPhoneNum(String(value));
        if (formattedPhone.length === 11 && formattedPhone.startsWith("0")) {
          const slicedPhoneNum = formattedPhone.slice(1);
          setValue?.(name, slicedPhoneNum);
        } else {
          setValue?.(name, formattedPhone);
        }
      }
    }, [value, onChange]);

    return (
      <div className={`mb-1 flex w-full flex-col ${className}`}>
        {label && <Label label={label} isRequired={isRequired} />}
        <div
          className={`relative border flex items-center justify-start ${
            error ? "border-red-500" : "border-[#D0D5DD]"
          } rounded-lg focus:outline-none focus:border-brand-600 text-gray400 text-sm py-[10px] w-full ${className}`}
        >
          <div
            className="flex h-[100%] cursor-pointer items-center space-x-1 border-r px-1"
            onClick={toggleDropdown}
          >
            <div className="text-s ml-2">
              <p className="text-sm">{selectedCode}</p>
            </div>
            <BiChevronDown />
          </div>

          {open ? (
            <div
              ref={dropdownRef}
              className="absolute top-12 left-0 z-20 flex h-[200px] flex-col overflow-y-scroll rounded border bg-white shadow"
            >
              <input
                type="text"
                className="border border-[#D0D5DD] w-auto py-1 mx-1 mt-1 px-2 sticky top-[5px] left-0 right-0 z-10 bg-white"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {countryPhoneCode?.map((el) => {
                return (
                  <div
                    key={el.flag}
                    className="flex w-[250px] cursor-pointer space-x-1 border-b p-2 text-sm"
                    onClick={() => handleClick(el.number)}
                  >
                    <p className="ml-2 w-[40px]">{el.number}</p>
                    <p className="ml-2 line-clamp-1">{el.name}</p>
                  </div>
                );
              })}
            </div>
          ) : null}
          <input
            {...{ ref, placeholder, id: rest.id, name }}
            onChange={onChange}
            type={"text"}
            placeholder={placeholder}
            className="h-full w-full border border-none bg-transparent px-2"
            {...rest}
          />
        </div>
        {error && (
          <small className="text-xs text-red-500 transition-all duration-300">
            {error}
          </small>
        )}
      </div>
    );
  }
);

InputPhone.displayName = "InputPhone";
export default InputPhone;
