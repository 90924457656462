/* eslint-disable */
/* eslint-disable no-useless-catch */

import { useDispatch } from "react-redux";
import { ApiRequest } from "../ApiNetwork";
import { setIsAuthentication, setUser } from "@/stores/reducers/user-reducers";
import { User } from "@/models/User";
import {
  CompleteRegisterPayload,
  TCorporateLogin,
  TForgotPassword,
  TLogin,
  TResetPassword,
} from "@/types/validations/auth";
import { setErrors, setSuccessMessages } from "@/stores/reducers/app-reducers";
import { useNavigate } from "react-router-dom";
import { setAccessToken } from "@/helpers/authHelpers";
import { APP_ROUTES } from "@/constants/app-routes";

export const useAuthServices = () => {
  const { request } = ApiRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authLogin = async (payload: TLogin): Promise<User | undefined> => {
    try {
      const response = await request("POST", {
        url: "/auth/login",
        payload,
      });
      if (response.status === "success") {
        const returnRes = response.data?.data as User;
        dispatch(setSuccessMessages([response?.data?.message]));
        dispatch(setIsAuthentication(true));
        dispatch(setUser(returnRes));
        return returnRes;
      } else {
        if (response.code === 401) {
          dispatch(setErrors([response.data]));
        }
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const authcorporateLogin = async (
    payload: TCorporateLogin
  ): Promise<User | undefined> => {
    try {
      const response = await request("POST", {
        url: "/auth/new-login",
        payload,
      });
      if (response.status === "success") {
        const returnRes = response.data?.data as User;
        dispatch(setSuccessMessages([response?.data?.message]));
        dispatch(setIsAuthentication(true));
        dispatch(setUser(returnRes));
        return returnRes;
      } else {
        if (response.code === 401) {
          dispatch(setErrors([response.data]));
        }
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const authRegister = async (payload: CompleteRegisterPayload) => {
    try {
      const response = await request("POST", {
        url: "/auth/corporate/register",
        payload,
      });
      if (response.status === "success") {
        const returnRes = response.data?.data as User;
        setAccessToken(response?.data?.data?.access_token);
        dispatch(setSuccessMessages([response?.data?.message]));
        dispatch(setUser(returnRes));
        return returnRes;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const authVerifyRegistrationOtp = async (payload: {
    verification_token: string;
    email: string;
  }) => {
    try {
      const response = await request("POST", {
        url: "/auth/verify/token",
        payload,
      });
      if (response.status === "success") {
        dispatch(setSuccessMessages([response?.data?.message]));
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const authVerifyPasswordOtp = async (payload: {
    verification_token: string;
    phone: string;
  }) => {
    try {
      const response = await request("POST", {
        url: "/auth/verify/password/token",
        payload,
      });
      if (response.status === "success") {
        setAccessToken(response?.data?.data.data);
        dispatch(setSuccessMessages([response?.data?.message]));
        // navigate(APP_ROUTES.RESET_PASSWORD);
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const authForgotPassword = async (payload: TForgotPassword) => {
    try {
      const response = await request("POST", {
        url: "/auth/forget/password",
        payload,
      });
      if (response.status === "success") {
        dispatch(setSuccessMessages([response?.data?.message]));
        navigate(APP_ROUTES.VERIFY_OTP, {
          state: {
            email: payload.phone,
            type: "password",
          },
        });
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const authResetPassword = async (payload: TResetPassword) => {
    try {
      const response = await request("POST", {
        url: "/auth/forget/password/change",
        payload,
      });
      if (response.status === "success") {
        dispatch(setSuccessMessages([response?.data?.message]));
        navigate(APP_ROUTES.PASSWORD_RESET_SUCCESS);
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const authResendOtp = async (payload: TForgotPassword) => {
    try {
      const response = await request("POST", {
        url: "/auth/resend/verify/token",
        payload,
      });
      if (response.status === "success") {
        dispatch(setSuccessMessages([response?.data?.message]));
      } else {
        if (response.code === 401) {
          dispatch(setErrors([response.data]));
        }
        // throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  return {
    authLogin,
    authcorporateLogin,
    authRegister,
    authVerifyPasswordOtp,
    authVerifyRegistrationOtp,
    authForgotPassword,
    authResetPassword,
    authResendOtp,
  };
};
