import { APP_ROUTES } from '@/constants/app-routes'
import { Fragment, useState } from 'react'
import { IoMdPerson } from 'react-icons/io'
import { IoBagOutline } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  CorporateLoginSchema,
  LoginSchema,
  TCorporateLogin,
  TLogin,
} from '@/types/validations/auth'
import PrimaryButton from '@/components/Button/PrimaryButton'
import InputPassword from '@/components/Input/InputPassword'
import { useMutation } from '@tanstack/react-query'
import { useAuthServices } from '@/services/auth'
import Seo from '@/components/Seo'
import { setAccessToken } from '@/helpers/authHelpers'
import InputPhone from '@/components/Input/InputPhone'
import Input from '@/components/Input/Input'
import { MdOutlineMail } from 'react-icons/md'
import Label from '@/components/Label/Label'
import { useDisclosure } from '@/hooks/useDisclosure'
import Modal from '@/components/Modal/Modal'
// import { phoneNumberWoPlus } from "@/utils";

const Login = () => {
  const [loginType, setLoginType] = useState('corporate')
  const [countryCode, setCountryCode] = useState<string>('+234')
  const [usephone, setUsephone] = useState(false)
  const type = ['corporate', 'personal']
  const { authLogin, authcorporateLogin } = useAuthServices()
  const navigate = useNavigate()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<TLogin>({
    mode: 'onBlur',
    resolver: zodResolver(LoginSchema),
    reValidateMode: 'onChange',
  })

  const {
    watch: corporateWatch,
    setValue: cSetVal,
    register: corporateRegister,
    formState: { errors: corporateError },
    handleSubmit: handleCorporateSubmit,
  } = useForm<TCorporateLogin>({
    mode: 'onBlur',
    resolver: zodResolver(CorporateLoginSchema),
    reValidateMode: 'onChange',
  })

  // const phoneNumber = watch("phone");

  const onSubmit: SubmitHandler<TLogin> = async (data) => {
    // mutate({...data, phone: phoneNumberWoPlus(countryCode, phoneNumber)});
    mutate(data)
  }

  const onCorporateSubmit: SubmitHandler<TCorporateLogin> = async (data) => {
    // mutate({...data, phone: phoneNumberWoPlus(countryCode, phoneNumber)});
    corporateFn(data)
  }

  const { mutate, isPending } = useMutation({
    mutationFn: authLogin,
    onSuccess: (res) => {
      setAccessToken(res?.access_token ?? '')
      // navigate(APP_ROUTES.DASHBOARD);
      navigate(APP_ROUTES.HOME)
    },
  })

  const { mutate: corporateFn, isPending: isCorporatePending } = useMutation({
    mutationFn: usephone ? authLogin : authcorporateLogin,
    onSuccess: (res) => {
      if (loginType === 'corporate' && res?.user_data.type === 'USER') {
        onOpen()
        setAccessToken(res?.access_token ?? '')
      } else {
        setAccessToken(res?.access_token ?? '')
        navigate(APP_ROUTES.DASHBOARD)
      }
    },
  })

  return (
    <>
      <section className="w-full max-w-[342px]">
        <Seo title="Point2 | Login" />
        <h1 className="text-gray-700 text-[30px] font-medium">
          Login to Account
        </h1>
        <div className="flex items-center gap-1 mt-2">
          {type.map((t, idx) => (
            <Fragment key={idx}>
              <div
                onClick={() => setLoginType(t)}
                className={`${
                  t === loginType ? 'bg-brand-600' : 'bg-transparent'
                } py-2 px-4 rounded-full cursor-pointer`}
              >
                <div className="flex items-center gap-1">
                  {t === 'personal' ? (
                    <IoMdPerson
                      className={`${
                        t === loginType ? 'text-white' : 'text-gray-400'
                      }`}
                      size={24}
                    />
                  ) : (
                    <IoBagOutline
                      className={`${
                        t === loginType ? 'text-white' : 'text-gray-400'
                      }`}
                      size={20}
                    />
                  )}
                  <p
                    className={`${
                      t === loginType ? 'text-white' : 'text-gray-400'
                    } text-sm capitalize`}
                  >
                    {t}
                  </p>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
        <Link to={APP_ROUTES.CREATE_ACCOUNT}>
          <p className="text-gray-400 text-sm font-medium mt-4">
            Don’t have an account? <span className="font-bold">Register</span>
          </p>
        </Link>
        <form
          className="mt-20 flex flex-col gap-2"
          onSubmit={
            loginType === 'personal'
              ? handleSubmit(onSubmit)
              : handleCorporateSubmit(onCorporateSubmit)
          }
        >
          {loginType === 'corporate' && !usephone && (
            <div>
              <div className="flex items-center justify-between">
                <Label label="Email Address" isRequired />
                <p
                  className="text-brand-600 font-semibold cursor-pointer"
                  onClick={() => setUsephone(true)}
                >
                  Use phone number
                </p>
              </div>
              <Input
                preIcon={<MdOutlineMail size={20} className="text-gray-500" />}
                placeholder="example@mail.com"
                {...corporateRegister('email')}
                error={corporateError.email?.message}
              />
            </div>
          )}
          {loginType === 'corporate' && usephone && (
            <div>
              <div className="flex items-center justify-between">
                <Label label="Phone Number" isRequired />
                <p
                  className="text-brand-600 font-semibold cursor-pointer"
                  onClick={() => setUsephone(false)}
                >
                  Use Email
                </p>
              </div>
              <InputPhone
                id="phone"
                placeholder="9020202020"
                setSelectedCode={setCountryCode}
                selectedCode={countryCode}
                {...corporateRegister('phone')}
                error={corporateError.phone?.message}
                value={corporateWatch('phone')}
                setValue={cSetVal}
                name="phone"
              />
            </div>
          )}
          {loginType === 'personal' && (
            <div>
              <InputPhone
                id="phone"
                placeholder="9020202020"
                label="Phone Number"
                setSelectedCode={setCountryCode}
                selectedCode={countryCode}
                {...register('phone')}
                error={errors.phone?.message}
                value={watch('phone')}
                setValue={setValue}
                name="phone"
                isRequired
              />
            </div>
          )}
          {loginType === 'personal' ? (
            <InputPassword
              isRequired
              label="Password"
              placeholder="*******"
              {...register('password')}
              error={errors.password?.message}
            />
          ) : (
            <InputPassword
              isRequired
              label="Password"
              placeholder="*******"
              {...corporateRegister('password')}
              error={corporateError.password?.message}
            />
          )}
          <div className="mt-10">
            <PrimaryButton
              text="Log in"
              variant="primary"
              className="w-full"
              isLoading={
                loginType === 'personal' ? isPending : isCorporatePending
              }
              disabled={
                loginType === 'personal' ? isPending : isCorporatePending
              }
            />
          </div>
          <Link to={APP_ROUTES.FORGOT_PASSWORD}>
            <p className="text-gray-700 text-base font-medium text-center mt-2">
              Forgot Password?{' '}
            </p>
          </Link>
        </form>
      </section>

      {isOpen && (
        <Modal
          title={`This is a personal account!\nDo you want to switch your account type to corporate?`}
          onClose={() => {
            onClose()
            navigate(APP_ROUTES.HOME)
          }}
        >
          <div className="flex gap-5 items-center">
            <PrimaryButton
              variant="primary"
              text="Yes"
              onClick={() => navigate(APP_ROUTES.SWITCH_ACCOUNT)}
            />
            <PrimaryButton
              variant="primary"
              text="No"
              onClick={() => {
                onClose()
                navigate(APP_ROUTES.HOME)
              }}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default Login
