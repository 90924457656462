// import Accordion from "@/components/Accordion/Accordion";
import PrimaryButton from '@/components/Button/PrimaryButton'
import Input from '@/components/Input/Input'
// import SideModal from "@/components/Modal/SideModal";
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
// import { CiCalendar } from "react-icons/ci";
// import { DatePicker } from "antd";
import { RootState } from '@/stores/store'
import { useSelector } from 'react-redux'
import { amountFormat, getLastDayOfNextMonthFromToday } from '@/utils'

type IProps = {
  chargesVisibility: boolean
  onChargesOpen: () => void
  onChargesClose: () => void
  onSuccessOpen: () => void
  onReset: any
  setCoupon: Dispatch<SetStateAction<string>>
  setPayeeType: Dispatch<SetStateAction<string>>
  coupon: string
  payeeType: string
  onSubmit: any
  isConfirm: boolean
}

const PaymentMethod = ({
  // chargesVisibility,
  onChargesClose,
  setCoupon,
  setPayeeType,
  payeeType,
  coupon,
  isConfirm,
}: // onChargesOpen,
IProps) => {
  const { user } = useSelector((state: RootState) => state?.user)
  // const { getTrafficLevelFromDirections } = useGoogleApi();
  const userObj = user?.user_data || user?.data
  // const [coupon, setCoupon] = useState('')
  const [paymentType, setPaymentType] = useState('')
  // const [payeeType, setPayeeType] = useState(`${userObj?.company_name} pay`)
  const payType = ['pay with transfer', 'card payment']
  const payee = [`${userObj?.company_name} pay`, 'receiver pay']
  const { orderCharges } = useSelector((state: RootState) => state.app)

  // const disablePastDates = (current: dayjs.Dayjs | null): boolean => {
  //   return current !== null && current < dayjs().startOf("day");
  // };
  // const [accordionIdx, setAccordionIdx] = useState<number[]>([]);

  // const toggleAccordion = (index: number) => {
  //   setAccordionIdx((prevState) =>
  //     prevState.includes(index)
  //       ? prevState.filter((i) => i !== index)
  //       : [...prevState, index]
  //   );
  // };

  const getSavedData = () => {
    const savedData = localStorage.getItem('requestData')
    if (savedData) {
      // const parsedData = JSON.parse(savedData)
      // dispatch(setFilledOrderRequest(parsedData))
    }
  }

  // const getChargesFn = async () => {
  //   if (orderRequest?.userInput) {
  //     const { pickup_location_coordinate } = orderRequest?.userInput;
  //     const { deliveryLocations } = orderRequest;

  //     if (!pickup_location_coordinate?.length) {
  //       Toast.error("Kindly fill the pickup details and save!");
  //       return;
  //     }

  //     if (!deliveryLocations?.length) {
  //       Toast.error("Kindly fill the delivery details and save!");
  //       return;
  //     }

  //     const deliveryData = await Promise.all(
  //       deliveryLocations.map(async (location) => {
  //         const distance = getDistanceFromLatLonInKm(
  //           pickup_location_coordinate[0],
  //           pickup_location_coordinate[1],
  //           location.delivery_point_location_coordinate[0],
  //           location.delivery_point_location_coordinate[1]
  //         );

  //         const trafficLevel = await getTrafficLevelFromDirections(
  //           pickup_location_coordinate.join(","),
  //           location.delivery_point_location_coordinate.join(",")
  //         );

  //         return {
  //           km: distance.toFixed(2),
  //           delivery_type:
  //             location.delivery_type === "Standard Delivery"
  //               ? "STANDARD_DELIVERY"
  //               : "EXPRESS_DELIVERY",
  //           traffic_level: trafficLevel ?? "",
  //           coupon,
  //         };
  //       })
  //     );

  //     mutate({ order_details: deliveryData });
  //   } else {
  //     Toast.error(
  //       "Kindly fill up the pickup and delivery details and save them first!"
  //     );
  //   }
  // };

  useEffect(() => {
    getSavedData()
  }, [])

  const totalDeliveryFee = orderCharges.reduce(
    (total, charge) => total + charge,
    0
  )

  return (
    <>
      <div>
        {/* {userObj?.type !== "USER" && ( */}
        <>
          <p className="text-gray-700 font-medium text-[18px]">
            Delivery Summary
          </p>
          {/* <div className="mt-3 rounded bg-[#f7f4ff] p-3 flex justify-between items-center">
            <p className="text-gray-600 font-medium text-sm">
              Scheduled payment
            </p>
            <div className="flex gap-2 items-center">
              <CiCalendar size={24} className="text-gray-500" />
              <p className="text-gray-600 font-medium text-sm">{date}</p>
              <DatePicker
                placeholder={"7/07/2025"}
                disabledDate={disablePastDates}
                onChange={dateOnChange}
                className=" text-gray-600 font-medium text-sm bg-transparent border-none h-0 placeholder:text-gray-600"
              />
            </div>
          </div> */}
          <p className="text-gray-600 font-medium text-sm">
            {getLastDayOfNextMonthFromToday().toDateString()}
          </p>
          <p className="text-gray-400 text-xs font-medium mt-3">
            Your payment will be due on the date scheduled above
          </p>
        </>
        {/* )} */}

        <>
          <p className="text-gray-700 font-medium text-[18px]">
            Payment Method
          </p>
          <div className="flex flex-col gap-5 mt-3">
            {payType.map((del, idx) => (
              <div
                onClick={() => {
                  setPaymentType(del)
                  onChargesClose()
                }}
                key={idx}
                className="flex items-center justify-between mt-2"
              >
                <p className="text-gray-700 text-sm font-medium capitalize">
                  {del}
                </p>
                <div
                  className={`h-5 w-5 rounded-full ${
                    paymentType === del
                      ? 'border-2 border-brand-600 bg-brand-600'
                      : 'bg-[#D9D9D9]'
                  }`}
                ></div>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-2 mt-3 bg-gray-50 px-4 py-2 rounded-lg">
            {payee.map((del, idx) => (
              <div
                onClick={() => {
                  setPayeeType(del)
                  onChargesClose()
                }}
                key={idx}
                className="flex items-center justify-between mt-2"
              >
                <p className="text-gray-700 text-sm font-medium capitalize">
                  {del}
                </p>
                <div
                  className={`h-5 w-5 rounded-full ${
                    payeeType === del
                      ? 'border-2 border-brand-600 bg-brand-600'
                      : 'bg-[#D9D9D9]'
                  }`}
                ></div>
              </div>
            ))}
          </div>
        </>

        {/* <div className="mt-5">
          {chargesVisibility && (
            <p className="text-gray-500 text-xs font-medium">
              Total Deliveries ({orderCharges?.length ?? "0"})
            </p>
          )}
          <div className="mt-3 flex flex-col gap-5">
            {chargesVisibility &&
              orderCharges?.map((order, idx) => (
                <div key={idx} className="bg-brand-25 px-3 rounded">
                  <Accordion
                    isBorderBotton={false}
                    titleStyle={"text-gray-600 text-xs"}
                    key={idx}
                    title={`Order ${idx + 1}`}
                    isCollapsed={accordionIdx.includes(idx)}
                    toggleSection={() => toggleAccordion(idx)}
                    style={{ paddingTop: "12px" }}
                  >
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center justify-between">
                        <p className="text-gray-600 text-xs">Discount </p>
                        <p className="text-gray-600 text-sm font-medium">
                          {order.discount}
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-gray-600 text-xs">
                          VAT ({order?.vat_percentage})
                        </p>
                        <p className="text-gray-600 text-sm font-medium">
                          {amountFormat(order?.vat.toString())}
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-gray-600 text-xs">Delivery fee</p>
                        <p className="text-gray-600 text-sm font-medium">
                          {amountFormat(order?.delivery_fee.toString())}
                        </p>
                      </div>
                    </div>
                  </Accordion>
                </div>
              ))}
          </div>
        </div> */}

        <div className="mt-6">
          <Input
            placeholder="Apply Coupon Code"
            name="coupon"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          />
        </div>
        {/* <div className="mt-8">
          <PrimaryButton
            variant="primary"
            text="Get Charges"
            className="w-full"
            onClick={getChargesFn}
            isLoading={isPending}
            disabled={chargesVisibility || isPending}
          />
        </div> */}
        {/* {chargesVisibility && ( */}
        <div className="mt-6">
          <div className="space-y-3">
            <div className="bg-gray-50 p-4 flex flex-col justify-center items-center">
              <p className="text-gray-600 text-base font-medium">TOTAL</p>
              <p className="text-gray-800 font-bold text-lg">
                {amountFormat(totalDeliveryFee.toString())}
              </p>
            </div>
          </div>
          <div className="mt-7">
            {/* <span className="text-xs text-red-600">
              Kindly save on every added order before confirming!
            </span> */}
            <PrimaryButton
              variant="primary"
              text="Confirm Order"
              className="w-full"
              // onClick={() => confirmOrder(orderRequest)}
              // isLoading={isConfirm}
              // disabled={isConfirm}
              isLoading={isConfirm}
              // disabled={!chargesVisibility || isConfirm}
              disabled={paymentType === '' || isConfirm}
            />
          </div>
          <a
            href="https://point2.ng/terms-conditions"
            target="_blank"
            className=""
          >
            <p className="text-gray-400 font-medium text-xs text-center cursor-pointer mt-8">
              By continuing, you agree to Point2{' '}
              <span className="text-gray-700">Terms & Condition</span> and my
              package align with Point 2 Guidelines
            </p>
          </a>
        </div>
        {/* )} */}
      </div>
    </>
  )
}

export default PaymentMethod
