import { FiPhone } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import Logo from "@/assets/images/logo.png";
import PlaystoreIcon from "@/assets/icons/playstore-icon.png";
import AppstoreIcon from "@/assets/icons/app-store-icon.png";
import { APP_ROUTES } from "@/constants/app-routes";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "@/components/Button/PrimaryButton";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useGlobalContext } from "@/stores/context/GlobalContext";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/stores/store";
import { useDisclosure } from "@/hooks/useDisclosure";
import Bars from "@/assets/svgs/bar.svg";
import MobileNav from "./MobileNav";
import useScreenSize from "@/hooks/useScreenSize";
import {
  clearUser,
  setIsAuthentication,
} from "@/stores/reducers/user-reducers";
import { removeToken } from "@/helpers/authHelpers";

const Navbar = () => {
  const location = useLocation();
  const screenWidth = useScreenSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { team, contact, scrollToSection } = useGlobalContext();
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.user
  );
  const userData = user?.data || user?.user_data;
  const { onToggle, isOpen } = useDisclosure();
  const {
    onClose: onCloseMobileNav,
    onOpen: onOpenMobileNav,
    isOpen: isOpenMobileNav,
  } = useDisclosure();

  const logout = () => {
    dispatch(setIsAuthentication(false));
    dispatch(clearUser());
    localStorage.removeItem("requestData");
    removeToken();
    navigate(APP_ROUTES.HOME);
  };

  return (
    <header className="sticky top-0 right-0 left-0 z-50">
      <div className="bg-brand-600 flex flex-col items-center">
        <div className="py-4 w-full max-w-screen-xl px-4">
          <div className="flex max-450:flex-col flex-row items-center justify-between">
            <p className="text-white text-sm font-medium">
              Get 30% off on all Delivery Orders on our mobile app.
            </p>
            <div className="flex max-450:flex-col-reverse flex-row items-center max-450:gap-2 gap-4">
              <a
                className="flex flex-row items-center gap-2"
                href="tel:02018891769"
              >
                <FiPhone color="white" size={20} />
                <p className="text-white text-sm font-medium">02018891769</p>
              </a>
              <div className="flex flex-row items-center gap-2">
                <GrLocation color="white" size={20} />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.google.com/maps?q=${encodeURIComponent(
                    "26 Kayode Street, Ogba, Lagos, Nigeria"
                  )}`}
                  className="text-white text-sm font-medium"
                >
                  26 Kayode Street, Ogba, Lagos, NG
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="bg-white border-b border-b-gray-100 flex flex-col items-center relative">
        <div className="py3 w-full max-w-screen-xl px-3">
          <ul className="flex flex-row items-center justify-between list-none">
            <Link to="/">
              <img src={Logo} alt="" className="w-[90px] h-[90px]" />
            </Link>
            <div className="hidden navMenu flex-row items-center gap-[36px]">
              <NavLink
                to={APP_ROUTES.HOME}
                className="text-gray-500 text-sm font-medium"
              >
                Home
              </NavLink>
              <NavLink
                to={APP_ROUTES.ABOUT}
                className="text-gray-500 text-sm font-medium"
              >
                About
              </NavLink>
              {location.pathname === "/about" && (
                <li
                  className="text-gray-500 text-sm font-medium cursor-pointer"
                  onClick={() => scrollToSection(team)}
                >
                  Team
                </li>
              )}
              <li
                className="text-gray-500 text-sm font-medium cursor-pointer"
                onClick={() => scrollToSection(contact)}
              >
                Contact us
              </li>
            </div>
            <div
              className={`${
                screenWidth >= 704 ? "flex" : "hidden"
              } items-center gap-2`}
            >
              {isAuthenticated ? (
                userData?.type === "Corporate" ? (
                  <PrimaryButton
                    variant="primary"
                    text="Dashboard"
                    onClick={() => navigate(APP_ROUTES.DASHBOARD)}
                  />
                ) : (
                  <PrimaryButton
                    className="bg-red-600"
                    variant="primary"
                    text="Logout"
                    onClick={logout}
                  />
                )
              ) : (
                <PrimaryButton
                  variant="primary"
                  text="Login"
                  onClick={() => navigate(APP_ROUTES.LOGIN)}
                />
              )}

              <PrimaryButton
                onClick={onToggle}
                variant="secondary"
                text="Download App"
                bg="bg-black"
                className="text-sm font-bold"
                postIcon={
                  <MdKeyboardArrowRight className="tex-white font-bold text-xl" />
                }
              />
              {isOpen && (
                <a
                  href="https://apps.apple.com/ng/app/point2/id6737227162"
                  className="border border-gray-50 bg-[#f9fafb] py-[10px] px-[18px]"
                >
                  <img src={AppstoreIcon} alt="" />
                </a>
              )}
              {isOpen && (
                <a
                  href="https://play.google.com/store/apps/details?id=com.point2.user"
                  className="border border-gray-50 bg-[#f9fafb] py-[10px] px-[18px]"
                >
                  <img src={PlaystoreIcon} alt="" />
                </a>
              )}
            </div>
            {/* bar */}
            <div
              className="relative navBarToggle cursor-pointer"
              onClick={onOpenMobileNav}
            >
              <img src={Bars} alt="" />
            </div>
            {/* mobile nav */}
            <div
              className={`${
                isOpenMobileNav ? "left0" : "left-100"
              } navMobileVisibility mobile-wrapper`}
            >
              <div className="mobile-overlay" onClick={onCloseMobileNav}></div>
              <MobileNav toggle={onCloseMobileNav} />
            </div>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
