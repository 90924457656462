import { z } from 'zod'
import { phone_number } from './contact'

export const CoordinateSchema = z.tuple([z.number(), z.number()])

const DeliveryLocationSchema = z.object({
  delivery_point_name: z.string().min(3, 'Receiver name is required'),
  delivery_point_phone: phone_number,
  destination_landmark: z.string().optional(),
  destination_location_name: z.string().optional(),
  delivery_point_email: z.string().optional(),
  rate_card_id: z.string().min(1, 'Rate card is required'),
  delivery_point_location: z
    .string({ required_error: 'Delivery location is required.' })
    .min(3, 'Delivery location is required'),
  delivery_point_location_coordinate: CoordinateSchema,
  package_category: z.string().min(2, 'Package category is required'),
  package_name: z.string().min(3, 'Package name is required'),
  delivery_type: z.enum(['Corporate Delivery', 'Express Delivery']),
})

const UserInputSchema = z.object({
  customer_name: z
    .string({ required_error: 'Customer name is required.' })
    .min(3, 'Customer name must be longer than 3 characters'),
  customer_phone: phone_number,
  //   customer_email: email,
  pickup_location: z
    .string({ required_error: 'Pickup location is required.' })
    .min(3, 'Pickup location must be longer than 3 characters'),
  pickup_location_coordinate: CoordinateSchema,
  landmark: z.string().optional(),
  pickup_location_name: z.string().optional(),
})

export const RequestSchema = z.object({
  manualLocationSelected: z.boolean().default(false),
  userInput: UserInputSchema,
  deliveryLocations: z
    .array(DeliveryLocationSchema)
    .nonempty('At least one delivery location is required'),
})

export type TRequestDetails = z.infer<typeof RequestSchema>
