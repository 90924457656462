import { useGoogleApi } from "@/hooks/useGoogleApi";
import { RootState } from "@/stores/store";
import { useQuery } from "@tanstack/react-query";
import { IoLocationOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

const UserLocation = () => {
  const { getGeocodingData } = useGoogleApi();
  const { location } = useSelector((state: RootState) => state.user);

  const { data } = useQuery({
    queryKey: [location],
    queryFn: () => getGeocodingData({ ignoreErr: true }),
  });

  return (
    <div className="flex gap-3">
      <div className="bg-brand-25 rounded-lg h-[48px] w-[48px] flex items-center justify-center">
        <IoLocationOutline className="text-brand-600" size={26} />
      </div>
      <div>
        <p className="text-gray-600 text-sm font-medium">My Location</p>
        <p className="text-gray-900 font-bold text-base">
          {data?.formatted_address ?? "Current Location not available"}
        </p>
      </div>
    </div>
  );
};

export default UserLocation;
