/* eslint-disable */

import { APP_ROUTES } from '@/constants/app-routes'
import { useEffect, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar'
import DashboardNav from '@/components/DashboardNav'
import { getToken } from '@/helpers/authHelpers'
import { useSelector } from 'react-redux'
import { RootState } from '@/stores/store'
import { useProfile } from '@/services/profile'

const DashboardLayout = () => {
  const token = getToken()
  const { user } = useSelector((state: RootState) => state.user)
  const userObj = user?.data || user?.user_data
  const navigate = useNavigate()
  const location = useLocation()
  const { fetchUser } = useProfile()

  const getTitle = useMemo(() => {
    const path = location.pathname
    switch (path) {
      case APP_ROUTES.DASHBOARD:
        return userObj?.company_name ?? 'Home'
      case APP_ROUTES.REQUEST_RIDER:
        return 'New Order'
      case APP_ROUTES.ACTIVITY:
        return 'Activity'
      case APP_ROUTES.NOTIFICATIONS:
        return 'Notifications'
      case APP_ROUTES.PROFILE:
        return 'Profile'
      case APP_ROUTES.MORE:
        return 'Analytics & More'
      case APP_ROUTES.INVOICE:
        return 'Invoice'
      case APP_ROUTES.BULK_ORDER:
        return 'Bulk Order'
      default:
        return 'Dashboard'
    }
  }, [location.pathname, userObj?.company_name])

  useEffect(() => {
    if (!token) {
      // navigate(APP_ROUTES.HOME);
      return
    }

    ;(async () => {
      const res = await fetchUser()
      if (!res) {
        navigate(APP_ROUTES.HOME)
      }
    })()
  }, [token])

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full max-w-screen-2xl px3 flex">
        <Sidebar />
        <div style={{}} className="flex flex-col w-full min-1105:w-[80%]">
          <DashboardNav title={getTitle} />
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
