import "./App.css";
import Layout from "@/layouts/AppLayout";
import { Route, Routes } from "react-router-dom";
import { authRoutes, privateRoute, routes } from "@/utils/routes";
import AuthLayout from "@/layouts/AuthLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/stores/store";
import { Toast } from "@/config/toast";
import DashboardLayout from "@/layouts/DashboardLayout";
import { setLocation } from "./stores/reducers/user-reducers";
import Spinner from "./components/Spinner";
import { useProfile } from "./services/profile";
import { getToken } from "./helpers/authHelpers";

function App() {
  const { errors, successMessages } = useSelector(
    (state: RootState) => state.app
  );
  const { fetchUser } = useProfile();
  const dispatch = useDispatch();
  const [fetchedUser, setFetchedUser] = useState(true);
  // const { isLoading } = useQuery({
  //   queryKey: ["get_user"],
  //   queryFn: fetchUser,
  // });

  // check for token and fetch user if it exists
  useEffect(() => {
    (async () => {
      const token = getToken();
      if (!token) {
        setFetchedUser(false);
        return;
      }

      try {
        await fetchUser();
      } finally {
        setFetchedUser(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (errors.length > 0) {
      Toast.error(errors.join("\n"));
    }
  }, [errors]);

  useEffect(() => {
    if (successMessages.length > 0) {
      Toast.success(successMessages.join("\n"));
    }
  }, [successMessages]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      const watchId = navigator.geolocation.watchPosition((position) => {
        dispatch(
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
      });

      // Clean up
      return () => navigator.geolocation.clearWatch(watchId);
    }
  }, []);

  if (fetchedUser) return <Spinner />;
  return (
    <Routes>
      <Route element={<Layout />}>
        {routes?.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.element />}
          />
        ))}
      </Route>

      <Route element={<AuthLayout />}>
        {authRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.element />}
          />
        ))}
      </Route>

      <Route element={<DashboardLayout />}>
        {privateRoute.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.element />}
          />
        ))}
      </Route>
    </Routes>
  );
}

export default App;
