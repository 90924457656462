import React, { useEffect, useState } from "react";
import useFileSelect from "@/hooks/useFileSelect";
import { copyToClipboard } from "@/utils/customExp";
import { FiCopy } from "react-icons/fi";
import { SlCamera } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/stores/store";
import { useProfile } from "@/services/profile";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { setSuccessMessages } from "@/stores/reducers/app-reducers";
import Avi from "@/assets/images/profileImage.png";

const ProfileHeader = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const userObj = user?.data || user?.user_data;
  const dispatch = useDispatch();
  const { uploadImage, fetchUser } = useProfile();
  const [fileObject, setFileObject] = useState<File | File[] | null>(null);
  const { selectFile, imagePreview } = useFileSelect(setFileObject, {
    allowMultiple: false,
  });
  const queryClient = useQueryClient();

  const handleFileChange = (
    mediaType: "Images" | "Videos",
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    selectFile(mediaType, event);
  };

  const { mutate: uploadProfileImage } = useMutation({
    mutationFn: uploadImage,
    onSuccess: async (res) => {
      dispatch(setSuccessMessages([res.message]));
      await fetchUser();
      queryClient.invalidateQueries({ queryKey: ["get_user"] });
    },
  });

  useEffect(() => {
    if (fileObject) {
      uploadProfileImage(fileObject as File);
    }
  }, [fileObject]);

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2 relative mt-5">
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            handleFileChange("Images", e);
          }}
          className="hidden"
          id="file-input"
        />

        <label htmlFor="file-input" className="cursor-pointer">
          <div className="w-[64px] h-[64px] bg-[#a7a6a6] rounded-full flex justify-center items-center relative">
            {imagePreview ? (
              <img
                src={imagePreview as string}
                alt="profile picture preview"
                className="object-cover w-[64px] h-[64px] rounded-full"
              />
            ) : (
              <img
                src={userObj?.profile_picture ?? Avi}
                alt="profile picture preview"
                className="object-cover w-[64px] h-[64px] rounded-full"
              />
            )}
            <div className="absolute inset-0 flex justify-center items-center">
              <div className="bg-brand-600 h-6 w-6 rounded-full flex items-center justify-center">
                <SlCamera className="text-white" size={16} />
              </div>
            </div>
          </div>
        </label>
        <div className="flex flex-col gap-1">
          <p className="text-gray-800 font-bold text-lg">
            {userObj?.first_name} {userObj?.last_name}
          </p>
          <p className="text-gray-600 font-mednium text-sm">{userObj?.email}</p>
        </div>
      </div>

      {userObj?.type === "USER" && userObj?.coupon && (
        <div className="border-l border-l-gray-200">
          <div className="ml-4 flex flex-col gap-1">
            <p className="text-gray-800 text-sm">Coupon Code</p>
            <div className="flex items-center gap-2">
              <p className="text-brand-600 text-base font-bold">
                {userObj?.coupon}
              </p>
              <FiCopy
                className="text-brand-600 cursor-pointer"
                size={20}
                onClick={() => copyToClipboard(userObj?.coupon ?? "")}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileHeader;
