import PrimaryButton from "@/components/Button/PrimaryButton";
import AutocompleteSearch from "@/components/GooglePlaces";
import Input from "@/components/Input/Input";
import InputPhone from "@/components/Input/InputPhone";
import Label from "@/components/Label/Label";
import Seo from "@/components/Seo";
import { APP_ROUTES } from "@/constants/app-routes";
import { useGoogleApi } from "@/hooks/useGoogleApi";
// import { setAccessToken } from "@/helpers/authHelpers";
import { useProfile } from "@/services/profile";
import { RegisterTwoSchema, TRegisterTwo } from "@/types/validations/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineMail } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

const SwitchAccount = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState<string>("+234");
  const { getGeocodingData } = useGoogleApi();
  const { switchAccount } = useProfile();
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<TRegisterTwo>({
    mode: "onBlur",
    resolver: zodResolver(RegisterTwoSchema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<TRegisterTwo> = async (data) => {
    mutate({ ...data, ...state });
  };

  const { mutate, isPending } = useMutation({
    mutationFn: switchAccount,
    onSuccess: () => {
      navigate(APP_ROUTES.DASHBOARD);
    },
  });

  const handleDestinationPlace = async (
    details: google.maps.places.PlaceResult
  ) => {
    setValue("address", details?.formatted_address ?? "");
    setValue("address_coordinate", [
      details?.geometry?.location?.lat() ?? 0,
      details?.geometry?.location?.lng() ?? 0,
    ]);
  };

  const getCurrentLocation = async () => {
    const res = await getGeocodingData({ ignoreErr: false });
    setValue("address", res.formatted_address);
    setValue("address_coordinate", [
      res?.geometry?.location?.lat ?? 0,
      res?.geometry?.location?.lng ?? 0,
    ]);
  };

  return (
    <section className="justify-center items-center flex flex-col">
      <div className="w-full px-4 max-w-[450px]">
        <Seo title="Point2 | Switch account" />
        <h1 className="text-gray-700 text-[30px] font-medium">
          Switch Account
        </h1>
        <p className="text-gray-400 text-sm font-medium mt-3">
          Company details
        </p>
        <form className="mt-8 flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col justify-center items-center">
            <div className="w-full flex flex-col gap-1">
              <Input
                isRequired
                label="Company Name"
                placeholder="Company name"
                {...register("company_name")}
                error={errors.company_name?.message}
              />
              {/* <Input
                isRequired
                label="Company Address"
                placeholder="Company address"
                {...register("address")}
                error={errors.address?.message}
              /> */}
              <div className="flex gap-2">
                <Label label="Company address" isRequired />
                <p
                  onClick={getCurrentLocation}
                  style={{
                    color: "#0077b6",
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Use Current Location
                </p>
              </div>
              <AutocompleteSearch
                state={watch("address")}
                onSelectPlace={(details) => handleDestinationPlace(details)}
                placeholder="Search delivery location"
                setState={(value) => setValue("address", value.toString())}
              />
              {errors?.address?.message && (
                <small className="px-3 text-red-500">
                  {errors?.address?.message}
                </small>
              )}
              <Input
                isRequired
                label="Name Of Contact"
                placeholder="Contact name"
                {...register("name_of_contact")}
                error={errors.name_of_contact?.message}
              />
              <Input
                preIcon={<MdOutlineMail size={20} className="text-gray-500" />}
                isRequired
                label="Company’s Email"
                placeholder="example@mail.com"
                {...register("company_email")}
                error={errors.company_email?.message}
              />
              <div>
                <InputPhone
                  id="phone"
                  placeholder="9020202020"
                  label="Contact Phone Number"
                  setSelectedCode={setCountryCode}
                  selectedCode={countryCode}
                  {...register("contact_phone_number")}
                  setValue={setValue}
                  name={"contact_phone_number"}
                  value={watch("contact_phone_number")}
                  error={errors.contact_phone_number?.message}
                  isRequired
                />
              </div>
              <div className="mt-10 flex items-center gap-5">
                <PrimaryButton
                  type="button"
                  text="Go Back"
                  variant="light"
                  className="w-full bg-brand-25"
                  onClick={() => navigate(-1)}
                />
                <PrimaryButton
                  isLoading={isPending}
                  disabled={isPending}
                  type="submit"
                  text="Done"
                  variant="primary"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SwitchAccount;
