import { useDisclosure } from '@/hooks/useDisclosure'
// import PaymentMethod from "./components/payment-method";
import Request from './components/request'
import SideModal from '@/components/Modal/SideModal'
import { useRef, useState } from 'react'
import Accordion from '@/components/Accordion/Accordion'
// import { FiCopy } from "react-icons/fi";
// import { copyToClipboard } from "@/utils/customExp";
import PrimaryButton from '@/components/Button/PrimaryButton'
// import TrackingDetails from "@/components/TrackingDetails";
import { useSelector } from 'react-redux'
import { RootState } from '@/stores/store'
// import { amountFormat } from "@/utils";
import Seo from '@/components/Seo'

const NewOrder = () => {
  const resetRef: any = useRef(null)
  const { orderCharges } = useSelector((state: RootState) => state.app)
  const [coupon, setCoupon] = useState('')
  const [payeeType, setPayeeType] = useState('')

  const {
    isOpen: isSuccessOpen,
    onClose: onSuccessClose,
    onOpen: onSuccessOpen,
  } = useDisclosure()
  // const {
  //   isOpen: isTrakingOpen,
  //   onClose: onTrackingClose,
  //   onOpen: onTrackingOpen,
  // } = useDisclosure();
  const {
    isOpen: charges,
    onClose: onChargesClose,
    onOpen: onChargesOpen,
  } = useDisclosure()
  const [accordionIdx, setAccordionIdx] = useState<number[]>([])

  const toggleAccordion = (index: number) => {
    setAccordionIdx((prevState) =>
      prevState.includes(index)
        ? prevState.filter((i) => i !== index)
        : [...prevState, index]
    )
  }

  return (
    <>
      <div className="flex w-full min-hscreen">
        <Seo title="Point2 | Request Order" />
        <div className="borderr border-rgray-200 min835:w-[70%] w-full py5 overflow-yscroll hscreen px8">
          <Request
            chargesVisibility={charges}
            onChargesOpen={onChargesOpen}
            onChargesClose={onChargesClose}
            onSuccessOpen={onSuccessOpen}
            ref={resetRef}
            setCoupon={setCoupon}
            setPayeeType={setPayeeType}
            coupon={coupon}
            payeeType={payeeType}
          />
        </div>
        {/* <div className="w-[30%] wfull min-hscreen sticky top-0 left-0 min-835:block hidden">
          <div className="px-4 h-screen overflow-y-scroll overflow-hidden">
            <PaymentMethod
              onSuccessOpen={onSuccessOpen}
              chargesVisibility={charges}
              onChargesOpen={onChargesOpen}
              onChargesClose={onChargesClose}
              onReset={() => {
                if (resetRef.current) {
                  resetRef.current.resetForm();
                }
              }}
            />
          </div>
        </div> */}
      </div>
      {isSuccessOpen && (
        <SideModal
          onClose={onSuccessClose}
          className="max-w[500px] wfull"
          // style={{ width: "395p" }}
        >
          <div className="mt-6">
            <div className="flex flex-col items-center">
              <div className="bg-green-light w-[183px] h-[183px] rounded-full flex justify-center items-center">
                <div className="bg-green-primary h-[70px] w-[70px] rounded-full" />
              </div>
            </div>
            <div className="mt-7">
              <h2 className="text-gray-800 font-bold text-[30px] text-center">
                Rider on it’s way
              </h2>
              <p className="text-gray-800 text-sm font-medium text-center">
                We are sending a rider to pickup item shortly
              </p>
            </div>
            <div className="mt-5">
              <p className="text-gray-500 text-xs font-medium">
                Total Deliveries ({orderCharges?.length})
              </p>
              <div className="mt-3 flex flex-col gap-5">
                {orderCharges.map((_, idx) => (
                  <div className="bg-[#E5E5E529] px-3 rounded">
                    <Accordion
                      isBorderBotton={false}
                      titleStyle={'text-gray-600 text-xs'}
                      key={idx}
                      title={`Order ${idx + 1}`}
                      isCollapsed={accordionIdx.includes(idx)}
                      toggleSection={() => toggleAccordion(idx)}
                      style={{ paddingTop: '12px' }}
                    >
                      <div className="flex flex-col gap-3">
                        {/* <div className="flex items-center justify-between">
                          <p className="text-gray-600 text-xs">Delivery code</p>
                          <div className="flex items-center gap-2">
                            <p className="text-gray-600 text-sm font-medium">
                              5486
                            </p>
                            <FiCopy
                              className="text-brand-600 cursor-pointer"
                              size={20}
                              onClick={() => copyToClipboard("5486")}
                            />
                          </div>
                        </div> */}
                        <div className="flex items-center justify-between">
                          <p className="text-gray-600 text-xs">Discount</p>
                          <p className="text-gray-600 text-sm font-medium">
                            {/* {order.discount} */}
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-gray-600 text-xs">Delivery fee</p>
                          <p className="text-gray-600 text-sm font-medium">
                            {/* {amountFormat(order?.delivery_fee.toString())} */}
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-gray-600 text-xs">Status</p>
                          <p
                            className="text-orange-primary text-sm font-medium cursor-pointer"
                            // onClick={onTrackingOpen}
                          >
                            Pending
                          </p>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                ))}
              </div>
              <div className="mt-8">
                <PrimaryButton
                  variant="light"
                  className="bg-brand-25 font-bold text-base w-full"
                  text="Make New Order"
                  onClick={onSuccessClose}
                />
              </div>
            </div>
          </div>
        </SideModal>
      )}

      {/* {isTrakingOpen && (
        <TrackingDetails onClose={onTrackingClose} trackingId="" />
      )} */}
    </>
  )
}

export default NewOrder
