import { z } from "zod";
import { email, phone_number } from "./contact";
import { CoordinateSchema } from "./request";

export const password = z
  .string({ required_error: "Password is required." })
  .min(8, { message: "Password must be at least 8 characters long." });

export const confirm_password = z
  .string()
  .trim()
  .min(1, { message: "Confirm password is required." });

export const LoginSchema = z.object({
  // phone: phone_number,
  phone: z.string().optional(),
  password,
});

export const CorporateLoginSchema = z.object({
  email: z.string().trim().optional(),
  phone: z.string().optional(),
  password,
});

export const RegisterOneSchemaBase = z.object({
  phone: phone_number,
  email,
  firstname: z
    .string({ required_error: "First name is required." })
    .min(3, { message: "First name must be longer than 3 characters" }),
  lastname: z
    .string({ required_error: "First name is required." })
    .min(3, { message: "First name must be longer than 3 characters" }),
  password,
  password_confirmation: password,
});

export const RegisterTwoSchema = z.object({
  company_name: z.string({ required_error: "Company name is required." }),
  address: z.string({ required_error: "Company address is required" }),
  address_coordinate: CoordinateSchema,
  name_of_contact: z
    .string({ required_error: "Name of contact is required." })
    .min(3, { message: "Name of contact must be longer than 3 characters" }),
  company_email: email,
  contact_phone_number: phone_number,
});

export const RegisterOneSchema = RegisterOneSchemaBase.refine(
  (data) => data.password === data.password_confirmation,
  {
    message: "Passwords do not match",
    path: ["password_confirmation"],
  }
);

export const CompleteRegisterSchema = RegisterOneSchemaBase.merge(
  RegisterTwoSchema
).refine((data) => data.password === data.password_confirmation, {
  message: "Passwords do not match",
  path: ["password_confirmation"],
});

export const ResetPasswordSchema = z.object({
  password,
  confirm_password,
});

export const ForgotPasswordSchema = z.object({
  // email,
  phone: phone_number,
});

export type TLogin = z.infer<typeof LoginSchema>;
export type TCorporateLogin = z.infer<typeof CorporateLoginSchema>;
export type TRegisterOne = z.infer<typeof RegisterOneSchema>;
export type TRegisterTwo = z.infer<typeof RegisterTwoSchema>;
export type CompleteRegisterPayload = z.infer<typeof CompleteRegisterSchema>;
export type TResetPassword = z.infer<typeof ResetPasswordSchema>;
export type TForgotPassword = z.infer<typeof ForgotPasswordSchema>;
