import { ApiRequest } from '@/services/ApiNetwork'
import { setErrors } from '@/stores/reducers/app-reducers'
import { RootState } from '@/stores/store'
import { useDispatch, useSelector } from 'react-redux'

export const useGoogleApi = () => {
  const { location } = useSelector((state: RootState) => state.user)
  const { request } = ApiRequest()
  const dispatch = useDispatch()

  const getGeocodingData = async ({
    ignoreErr = false,
  }: {
    ignoreErr?: boolean
  }) => {
    try {
      const response = await request('GET', {
        url: `/google-location-api?latitude=${location?.latitude}&longitude=${location?.longitude}`,
      })

      if (
        response.data &&
        response.data.results &&
        response.data.results.length > 0
      ) {
        return response.data.results[1]
      } else {
        if (!ignoreErr) dispatch(setErrors(['Kindly enable device location.']))
      }
    } catch (error) {
      if (!ignoreErr) dispatch(setErrors(['Kindly enable device location.']))
    }
  }

  const getTrafficLevelFromDirections = async (
    origin: string,
    destination: string
  ) => {
    const response = await request('GET', {
      url: `/google-api?origin=${origin}&destination=${destination}`,
    })
    const trafficData = response.data.routes[0].legs[0]
    const durationInTraffic = trafficData.duration_in_traffic.value
    const durationWithoutTraffic = trafficData.duration.value

    const trafficLevel = durationInTraffic / durationWithoutTraffic

    if (trafficLevel > 1.3) {
      return 'heavy'
    } else if (trafficLevel > 1.1) {
      return 'moderate'
    } else {
      return 'normal'
    }
  }

  return {
    getGeocodingData,
    getTrafficLevelFromDirections,
  }
}
