import { Pagination } from '@/components/Pagination'
import Spinner from '@/components/Spinner'
import { APP_ROUTES } from '@/constants/app-routes'
import { BulkOrderData } from '@/models/Order'
import { useGetOrders } from '@/services/order'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'
import TableData from './TableData'

const Table = () => {
  const [page, setPage] = useState(1)
  const { getPendingBulkOrders } = useGetOrders()
  const [allOrders, setAllOrders] = useState({} as BulkOrderData)

  const { data: orders, isLoading } = useQuery({
    queryKey: ['get_pending_bulk_orders', page],
    queryFn: () => getPendingBulkOrders(page),
  })

  useEffect(() => {
    if (orders) {
      setAllOrders((state) => ({
        ...state,
        data: orders.orders?.data,
        links: orders.orders?.links,
        meta: orders.orders?.meta,
      }))
    }
  }, [orders])

  const allOrdersData = useMemo(() => {
    if (isLoading) {
      return (
        <tr>
          <th colSpan={8} className="text-center">
            <Spinner />
          </th>
        </tr>
      )
    }

    if (!isLoading && allOrders?.data?.length > 0) {
      return allOrders.data.map((order) => (
        <React.Fragment key={order?.id}>
          <TableData orders={order} />
        </React.Fragment>
      ))
    }

    if (!isLoading && allOrders?.data?.length === 0) {
      return (
        <tr>
          <th colSpan={9} className="text-center">
            No Record Found
          </th>
        </tr>
      )
    }
  }, [allOrders, isLoading])

  return (
    <>
      <table className="">
        <thead>
          <tr className="text-[#212529] table-header-light">
            <th>Order Id</th>
            <th>Customer Name</th>
            <th>Customer Email</th>
            <th>Total Amount</th>
            <th>Amount To Pay</th>
            <th>Delivery Status</th>
            <th>Payment Status</th>
            <th>Due Date</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{allOrdersData}</tbody>
      </table>
      {allOrders.data &&
        allOrders.data?.length > 0 &&
        allOrders.meta.total > 10 && (
          <nav className="custom-paginator">
            <ul>
              <Pagination
                currentPage={page}
                pages={Math.ceil(
                  allOrders.meta.total / allOrders.meta.per_page
                )}
                url={APP_ROUTES.BULK_ORDER}
                setPages={setPage}
              />
            </ul>
          </nav>
        )}
    </>
  )
}

export default Table
