/* eslint-disable */

import React, { useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";

interface autoCompleteProps {
  state: string;
  setState: (value: React.SetStateAction<string>) => void;
  onSelectPlace: (details: google.maps.places.PlaceResult) => void;
  placeholder?: string;
}

const AutocompleteSearch = (props: autoCompleteProps) => {
  const { state, setState, placeholder, onSelectPlace } = props;
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const handlePlacesChanged = () => {
    const places = autocompleteRef.current?.getPlace();
    if (places) {
      onSelectPlace(places);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Autocomplete
        onLoad={(ref) => (autocompleteRef.current = ref)}
        onPlaceChanged={handlePlacesChanged}
        options={{
          componentRestrictions: { country: "NG" },
        }}
      >
        <input
          type="text"
          placeholder={placeholder ?? "Search for a place"}
          value={state}
          onChange={(e) => setState(e.target.value)}
          className="w-full relative py-[10px] border border-[#D0D5DD] rounded-lg text-sm px-4 bg-white"
        />
      </Autocomplete>
    </div>
  );
};

export default AutocompleteSearch;
