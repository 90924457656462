import PrimaryButton from "@/components/Button/PrimaryButton";
import { APP_ROUTES } from "@/constants/app-routes";
import { removeToken } from "@/helpers/authHelpers";
import { useGlobalContext } from "@/stores/context/GlobalContext";
import {
  clearUser,
  setIsAuthentication,
} from "@/stores/reducers/user-reducers";
import { RootState } from "@/stores/store";
import { LiaTimesSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const MobileNav = ({ toggle }: { toggle: () => void }) => {
  const dispatch = useDispatch();
  const { team, contact, scrollToSection } = useGlobalContext();
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.user
  );
  const userData = user?.data || user?.user_data;
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    dispatch(setIsAuthentication(false));
    dispatch(clearUser());
    localStorage.removeItem("requestData");
    removeToken();
    navigate(APP_ROUTES.HOME);
  };

  return (
    <nav className="mobile-nav_content text-gray-600 text-xl font-medium flex flex-col relative">
      <span className="mobile-nav__close" onClick={toggle}>
        <LiaTimesSolid size={24} />
      </span>
      <div className="mobile-nav_container mt-12 flex-grow">
        <ul className="" onClick={toggle}>
          <NavLink to={APP_ROUTES.HOME}>
            <li className="py-6">Home</li>
          </NavLink>
          <NavLink to={APP_ROUTES.ABOUT}>
            <li className="py-6">About us</li>
          </NavLink>
          {location.pathname === APP_ROUTES.ABOUT && (
            <li onClick={() => scrollToSection(team)} className="py-6">
              Team
            </li>
          )}
          <li onClick={() => scrollToSection(contact)} className="py-6">
            Contact us
          </li>
          {/* <NavLink to={APP_ROUTES.LOGIN}>
            <li className="py-6">Login</li>
          </NavLink> */}
        </ul>
      </div>
      <div className="">
        {isAuthenticated ? (
          userData?.type === "Corporate" ? (
            <div className="w-full" onClick={toggle}>
              <PrimaryButton
                variant="primary"
                text="Dashboard"
                onClick={() => navigate(APP_ROUTES.DASHBOARD)}
              />
            </div>
          ) : (
            <div className="w-full" onClick={toggle}>
              <PrimaryButton
                className="bg-red-600"
                variant="primary"
                text="Logout"
                onClick={logout}
              />
            </div>
          )
        ) : (
          <div className="w-full" onClick={toggle}>
            <PrimaryButton
              variant="primary"
              text="Login"
              onClick={() => navigate(APP_ROUTES.LOGIN)}
            />
          </div>
        )}
      </div>
    </nav>
  );
};

export default MobileNav;
