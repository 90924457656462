/* eslint-disable */
/* eslint-disable no-useless-catch */

import { RateCard } from "@/models/RateCard";
import { ApiRequest } from "../ApiNetwork";

export const useRateCard = () => {
  const { request } = ApiRequest();

  const getUserRateCard = async () => {
    try {
      const response = await request("GET", {
        url: `/user/orders/bulk/rate-card/list`,
      });

      if (response.status === "success") {
        return response.data.data as RateCard[];
      }
    } catch (error) {}
  };

  return {
    getUserRateCard,
  };
};
