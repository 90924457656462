/* eslint-disable */

import { createContext, useContext, useRef } from "react";

type GlobalContextReturnType = {
  team: React.MutableRefObject<null>;
  contact: React.MutableRefObject<null>;
  scrollToSection: (elementRef: any) => void;
};

const GlobalContext = createContext<GlobalContextReturnType | null>(null);

export const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const team = useRef(null);
  const contact = useRef(null);

  const scrollToSection = (elementRef: any) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <GlobalContext.Provider value={{ scrollToSection, team, contact }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () =>
  useContext(GlobalContext) as GlobalContextReturnType;

export default GlobalContextProvider;
