import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AppState } from '@/models/App'
// import Charges from "@/models/Charges";
import { TRequestDetails } from '@/types/validations/request'

const initialState: AppState = {
  errors: [],
  successMessages: [],
  orders: [],
  orderCharges: [],
  orderRequest: {} as TRequestDetails,
}

const AppReducer = createSlice({
  name: 'appReducer',
  initialState,
  reducers: {
    setErrors: (state, action: PayloadAction<string[]>) => {
      return { ...state, errors: action.payload }
    },

    clearErrors: (state) => {
      return { ...state, errors: [] }
    },
    setSuccessMessages(state, action: PayloadAction<string[]>) {
      state.successMessages = action.payload
    },
    clearSuccessMessages(state) {
      state.successMessages = []
    },
    // setOrderCharges: (state, action: PayloadAction<Charges>) => {
    //   state.orderCharges = [...state.orderCharges, action.payload];
    // },
    setOrderCharges: (state, action: PayloadAction<number[]>) => {
      state.orderCharges = action.payload
    },
    clearOrderCharges: (state) => {
      state.orderCharges = []
    },
    setFilledOrderRequest: (state, action: PayloadAction<TRequestDetails>) => {
      state.orderRequest = action.payload
    },
  },
})

export const {
  setErrors,
  clearErrors,
  setSuccessMessages,
  clearSuccessMessages,
  setOrderCharges,
  setFilledOrderRequest,
  clearOrderCharges,
} = AppReducer.actions
export default AppReducer.reducer
