import PrimaryButton from '@/components/Button/PrimaryButton'
// import AutocompleteSearch from "@/components/GooglePlaces";
import Input from '@/components/Input/Input'
import InputPhone from '@/components/Input/InputPhone'
// import { InputSelect } from '@/components/Input/InputSelect'
import Label from '@/components/Label/Label'
// import { Toast } from '@/config/toast'
import { PackageCategories } from '@/constants/package-categories'
import useScreenSize from '@/hooks/useScreenSize'
// import { useGoogleApi } from "@/hooks/useGoogleApi";
import {
  clearOrderCharges,
  setFilledOrderRequest,
  setOrderCharges,
} from '@/stores/reducers/app-reducers'
import { RootState } from '@/stores/store'
import { RequestSchema, TRequestDetails } from '@/types/validations/request'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { LuMinusCircle } from 'react-icons/lu'
import { useDispatch, useSelector } from 'react-redux'
import PaymentMethod from './payment-method'
import { useRateCard } from '@/services/rate-card'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  amountFormat,
  getDistanceFromLatLonInKm,
  getLastDayOfNextMonthFromToday,
} from '@/utils'
import { useGetOrders } from '@/services/order'

type IProps = {
  chargesVisibility: boolean
  onChargesOpen: () => void
  onChargesClose: () => void
  onSuccessOpen: () => void
  setCoupon: Dispatch<SetStateAction<string>>
  setPayeeType: Dispatch<SetStateAction<string>>
  coupon: string
  payeeType: string
}

const Request = forwardRef(
  (
    {
      onChargesClose,
      onChargesOpen,
      chargesVisibility,
      onSuccessOpen,
      setCoupon,
      setPayeeType,
      payeeType,
      coupon,
    }: IProps,
    ref
  ) => {
    const screenWidth = useScreenSize()
    const deliveryType: Array<'Corporate Delivery'> = [
      'Corporate Delivery',
      // "Express Delivery",
    ]
    const [countryCode, setCountryCode] = useState<string>('+234')
    const { getUserRateCard } = useRateCard()
    const { requestOrders } = useGetOrders()
    // const [currentIdx, setCurrentIdx] = useState(0);
    const dispatch = useDispatch()
    // const { getGeocodingData } = useGoogleApi();
    const { user } = useSelector((state: RootState) => state.user)
    const date = `${getLastDayOfNextMonthFromToday().getFullYear()}-${String(
      getLastDayOfNextMonthFromToday().getMonth() + 1
    ).padStart(2, '0')}-${String(
      getLastDayOfNextMonthFromToday().getDate()
    ).padStart(2, '0')}`

    const userObj = user?.data || user?.user_data
    const {
      register,
      handleSubmit,
      setValue,
      getValues,
      watch,
      control,
      reset,
      clearErrors,
      formState: { errors },
    } = useForm<TRequestDetails>({
      resolver: zodResolver(RequestSchema),
      defaultValues: {
        manualLocationSelected: false,
        userInput: {
          // customer_email: "",
          customer_name: '',
          customer_phone: '',
          pickup_location: '',
          pickup_location_coordinate: [0, 0],
          landmark: '',
          pickup_location_name: '',
          // package_category: "",
        },
        deliveryLocations: [
          {
            delivery_point_name: '',
            delivery_point_phone: '',
            destination_landmark: '',
            delivery_point_email: '',
            delivery_point_location: '',
            delivery_point_location_coordinate: [0, 0],
            package_category: '',
            package_name: '',
            destination_location_name: '',
            rate_card_id: '',
            delivery_type: 'Corporate Delivery',
          },
        ],
      },
    })

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'deliveryLocations',
    })

    // const prefillForm = () => {
    //   const savedData = localStorage.getItem('requestData')
    //   if (savedData) {
    //     const parsedData = JSON.parse(savedData)
    //     reset(parsedData)
    //   }
    // }

    // const deliveryLocations = watch("deliveryLocations");
    const deliveryLocations = getValues('deliveryLocations')

    // Change this later to useMemo
    // const getPrice = useMemo(() => {
    const getPrice = () => {
      return deliveryLocations.map((detail) => {
        const rateCard = rateCards?.find(
          (rate) => rate.id === parseInt(detail.rate_card_id)
        )

        if (rateCard) {
          return rateCard.amount
        }
        return 0
      })
    }
    // }, [memoTrigger, rateCards]);

    // const saveData = (data: TRequestDetails) => {
    //   dispatch(setFilledOrderRequest(data))
    //   localStorage.setItem('requestData', JSON.stringify(data))
    //   Toast.success(
    //     data.deliveryLocations.length > 1
    //       ? 'Orders saved successfully!'
    //       : 'Order saved succesfully!'
    //   )
    // }
    const queryClient = useQueryClient()

    const { mutate: confirmFn, isPending: isConfirm } = useMutation({
      mutationFn: requestOrders,
      onSuccess: () => {
        onSuccessOpen()
        dispatch(clearOrderCharges())
        reset()
        queryClient.invalidateQueries({
          queryKey: ['get_bulk_orders', 'get_notifications'],
        })
      },
    })

    const onSubmit: SubmitHandler<TRequestDetails> = async (data) => {
      dispatch(setFilledOrderRequest(data))
      // saveData(data)
      // onChargesClose(); //un comment later when there is get charges
      onChargesOpen() // Remove later when there is get charges
      dispatch(setOrderCharges(getPrice())) // remove later when there is get charges

      const { pickup_location_coordinate } = data.userInput
      const { deliveryLocations } = data

      const deliveryData = await Promise.all(
        deliveryLocations.map(async (location) => {
          const distance = getDistanceFromLatLonInKm(
            pickup_location_coordinate[0],
            pickup_location_coordinate[1],
            location.delivery_point_location_coordinate[0],
            location.delivery_point_location_coordinate[1]
          )

          // const trafficLevel = await getTrafficLevelFromDirections(
          //   pickup_location_coordinate.join(","),
          //   location.delivery_point_location_coordinate.join(",")
          // );

          return {
            ...data.userInput,
            ...location,
            delivery_type:
              location.delivery_type === 'Corporate Delivery'
                ? 'EXPRESS_DELIVERY'
                : 'EXPRESS_DELIVERY',
            km: distance.toFixed(2),
            payee:
              payeeType === `${userObj?.company_name} pay`
                ? 'SENDER'
                : 'RECEIVER',
            // traffic_level: trafficLevel ?? "",
            traffic_level: 'normal',
            generate_code: 'NO',
            coupon,
          }
        })
      )

      confirmFn({ orders: deliveryData, due_date: date })
    }

    const handleAddLocation = () => {
      if (fields.length >= 1) onChargesClose()
      // setCurrentIdx(currentIdx + 1);
      append({
        delivery_point_name: '',
        delivery_point_phone: '',
        destination_landmark: '',
        delivery_point_email: '',
        delivery_point_location: '',
        delivery_point_location_coordinate: [0, 0],
        package_category: '',
        package_name: '',
        destination_location_name: '',
        rate_card_id: '',
        delivery_type: 'Corporate Delivery',
      })
    }

    // const resetToCurrentLocation = () => {
    //   setValue("manualLocationSelected", false);
    // };

    // const getCurrentLocation = async () => {
    //   console.log(location);

    //   let res = await getGeocodingData({ ignoreErr: false });
    //   setValue("userInput.pickup_location", res?.formatted_address);
    //   setValue(
    //     "userInput.pickup_location_name",
    //     res.address_components[4]?.long_name
    //   );
    //   setValue("userInput.pickup_location_coordinate", [
    //     location?.latitude ?? 0,
    //     location?.longitude ?? 0,
    //   ]);
    //   resetToCurrentLocation();
    // };

    // const handlePlaceSelection = async (
    //   details: google.maps.places.PlaceResult
    // ) => {
    //   console.log(
    //     details?.geometry?.location?.lat(),
    //     details?.geometry?.location?.lng()
    //   );
    //   setValue("userInput.pickup_location", details?.formatted_address ?? "");
    //   setValue("userInput.pickup_location_name", details?.name);
    //   setValue("userInput.pickup_location_coordinate", [
    //     details?.geometry?.location?.lat() ?? 0,
    //     details?.geometry?.location?.lng() ?? 0,
    //   ]);
    //   setValue("manualLocationSelected", true);
    // };

    // const handleDestinationPlace = async (
    //   index: number,
    //   details: google.maps.places.PlaceResult
    // ) => {
    //   setValue(
    //     `deliveryLocations.${index}.delivery_point_location`,
    //     details?.formatted_address ?? ""
    //   );
    //   setValue(
    //     `deliveryLocations.${index}.delivery_point_location_coordinate`,
    //     [
    //       details?.geometry?.location?.lat() ?? 0,
    //       details?.geometry?.location?.lng() ?? 0,
    //     ]
    //   );
    //   setValue(
    //     `deliveryLocations.${index}.destination_location_name`,
    //     details?.name ?? ""
    //   );
    //   setValue("manualLocationSelected", true);
    // };

    const { data: rateCards } = useQuery({
      queryKey: ['rate_card'],
      queryFn: getUserRateCard,
      staleTime: 60000,
    })

    useEffect(() => {
      if (user && rateCards) {
        // setValue("userInput.customer_email", user?.email || "");
        setValue('userInput.customer_name', userObj?.company_name || '')
        setValue('userInput.customer_phone', userObj?.phone || '')
        setValue(
          'userInput.pickup_location',
          userObj?.company_profile?.address || ''
        )
        setValue('userInput.pickup_location_coordinate', [
          userObj?.company_profile?.address_coordinate?.[0] || 0,
          userObj?.company_profile?.address_coordinate?.[1] || 0,
        ])
      }
    }, [user, rateCards])

    const handleSetValue = (name: any, arg: string) => {
      setValue(name, arg)
      clearErrors(name)
    }

    useEffect(() => {
      // prefillForm()
      dispatch(clearOrderCharges()) // to clear the charges response on every mount
    }, [])

    // expose the reset function to the parent via the ref
    useImperativeHandle(ref, () => {
      return { resetForm: reset }
    })

    return (
      <>
        <form
          className="flex-col flex min-835:flex-row w-full min-hscreen"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="border-r border-r-gray-200 min-835:w-[70%] w-full py-5 overflow-y-scroll h-screen px-8">
            <div
              className={`flex ${
                screenWidth >= 560 ? 'flex-row items-center' : 'flex-col gap-3'
              } justify-between`}
            >
              <div>
                <h2 className="text-gray-800 font-medium text-base">
                  Pickup Location
                </h2>
                <p className="text-gray-800 text-sm">Enter Pickup Details</p>
              </div>
              <div
                onClick={handleAddLocation}
                className="flex items-center gap-4 cursor-pointer"
              >
                <p className="text-sm font-bold text-brand-600">
                  Add New Delivery Location
                </p>
                <IoMdAddCircleOutline className="text-brand-600" size={24} />
              </div>
            </div>
            <div className="bg-[#fbfeff] rounded-lg w-full p-5 mt-4 space-y-3 border border-brand-25">
              <div
                className={`flex ${
                  screenWidth >= 560 ? 'flex-row gap-8' : 'flex-col gap-3'
                } items-center`}
              >
                <div className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}>
                  <Input
                    {...register('userInput.customer_name')}
                    placeholder="Account owner name auto display"
                    label="Customer name"
                    isRequired
                    error={errors.userInput?.customer_name?.message}
                  />
                </div>
                <div className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}>
                  <InputPhone
                    id="phone"
                    placeholder="9020202020"
                    label="Phone Number"
                    setSelectedCode={setCountryCode}
                    selectedCode={countryCode}
                    {...register('userInput.customer_phone')}
                    setValue={setValue}
                    value={watch('userInput.customer_phone')}
                    error={errors.userInput?.customer_phone?.message}
                    isRequired
                  />
                </div>
              </div>
              <div
                className={`flex ${
                  screenWidth >= 560 ? 'flex-row gap-8' : 'flex-col gap-3'
                } items-center`}
              >
                <div className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}>
                  {/* <AutocompleteSearch
                  state={watch("userInput.pickup_location")}
                  onSelectPlace={handlePlaceSelection}
                  placeholder="Search pickup location"
                  setState={(value) =>
                    setValue("userInput.pickup_location", value.toString())
                  }
                /> */}
                  <Input
                    {...register('userInput.pickup_location')}
                    placeholder="Enter pickup location"
                    label="Pickup address"
                    isRequired
                    readOnly
                    error={errors.userInput?.pickup_location?.message}
                  />
                  {/* {errors?.userInput &&
                  errors?.userInput.pickup_location?.message && (
                    <small className="px-3 text-red-500">
                      {errors?.userInput.pickup_location?.message}
                    </small>
                  )} */}
                </div>
                <div className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}>
                  <Input
                    {...register('userInput.landmark')}
                    label="Landmark or B/stop"
                    placeholder="Enter b/stop (optional)"
                    error={errors.userInput?.landmark?.message}
                  />
                </div>
              </div>
            </div>
            {fields.map((_, index) => (
              <div key={index} className="my-5">
                <h2 className="text-gray-800 font-medium text-base">
                  Delivery Location {fields.length > 1 && index + 1}
                </h2>
                <p className="text-gray-800 text-sm">Enter Delivery Details</p>
                <div className="bg-[#fbfeff] border border-brand-25 rounded-lg w-full p-5 mt-4 space-y-3">
                  <div
                    className={`flex ${
                      screenWidth >= 560 ? 'flex-row gap-8' : 'flex-col gap-3'
                    } items-center`}
                  >
                    <div
                      className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}
                    >
                      <Input
                        placeholder="enter item name"
                        label="Item name"
                        isRequired
                        {...register(`deliveryLocations.${index}.package_name`)}
                        error={
                          errors?.deliveryLocations?.[index]?.package_name
                            ?.message || ''
                        }
                      />
                    </div>
                    <div
                      className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}
                    >
                      {/* <InputSelect
                      label="Item Category"
                      name={`deliveryLocations.${index}.package_category`}
                      isRequired
                      choices={PackageCategories.map((datum) => {
                        return { value: datum, label: datum }
                      })}
                      value={watch(
                        `deliveryLocations.${index}.package_category`
                      )}
                      setValue={handleSetValue}
                      placeholder="Select Category"
                      error={
                        errors?.deliveryLocations?.[index]?.package_category
                          ?.message
                      }
                    /> */}

                      <div className="w-full my-2">
                        <Label label={'Item Category'} isRequired />
                        <select
                          onChange={(event) =>
                            handleSetValue(
                              `deliveryLocations.${index}.package_category`,
                              event.target.value
                            )
                          }
                          name={`deliveryLocations.${index}.package_category`}
                          id={`deliveryLocations.${index}.package_category`}
                          className={`w-full pl-4 pr-4 py-[10px] border ${
                            !errors?.deliveryLocations?.[index]
                              ?.package_category?.message
                              ? 'border-[#D0D5DD]'
                              : 'border-red-500'
                          } rounded-lg focus:outline-none focus:border-brand-600 text-gray400 text-sm`}
                        >
                          <option value="">Select Category</option>
                          {PackageCategories?.map((datum) => (
                            <option key={datum} value={datum}>
                              {datum}
                            </option>
                          ))}
                        </select>

                        {errors?.deliveryLocations &&
                          errors?.deliveryLocations?.[index]
                            ?.package_category && (
                            <small className="px-3 text-red-500">
                              {
                                errors?.deliveryLocations?.[index]
                                  ?.package_category.message
                              }
                            </small>
                          )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex ${
                      screenWidth >= 560 ? 'flex-row gap-8' : 'flex-col gap-3'
                    } items-center`}
                  >
                    <div
                      className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}
                    >
                      <Input
                        placeholder="enter reciever’s name"
                        label="Reciever’s name"
                        isRequired
                        {...register(
                          `deliveryLocations.${index}.delivery_point_name`
                        )}
                        error={
                          errors?.deliveryLocations?.[index]
                            ?.delivery_point_name?.message
                        }
                      />
                    </div>
                    <div
                      className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}
                    >
                      <InputPhone
                        id="phone"
                        placeholder="9020202020"
                        label="Phone Number"
                        setSelectedCode={setCountryCode}
                        selectedCode={countryCode}
                        {...register(
                          `deliveryLocations.${index}.delivery_point_phone`
                        )}
                        setValue={setValue}
                        name={`deliveryLocations.${index}.delivery_point_phone`}
                        value={watch(
                          `deliveryLocations.${index}.delivery_point_phone`
                        )}
                        error={
                          errors?.deliveryLocations?.[index]
                            ?.delivery_point_phone?.message
                        }
                        isRequired
                      />
                    </div>
                  </div>
                  <div
                    className={`flex ${
                      screenWidth >= 560 ? 'flex-row gap-8' : 'flex-col gap-3'
                    } items-center flex-row-reverse `}
                  >
                    <div
                      className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}
                    >
                      <div className="w-full my-2">
                        <Label label={'Delivery Location'} isRequired />
                        <select
                          onChange={(event) =>
                            handleSetValue(
                              `deliveryLocations.${index}.rate_card_id`,
                              event.target.value
                            )
                          }
                          name={`deliveryLocations.${index}.rate_card_id`}
                          id={`deliveryLocations.${index}.rate_card_id`}
                          className={`w-full pl-4 pr-4 py-[10px] border ${
                            !errors?.deliveryLocations?.[index]?.rate_card_id
                              ?.message
                              ? 'border-[#D0D5DD]'
                              : 'border-red-500'
                          } rounded-lg focus:outline-none focus:border-brand-600 text-gray400 text-sm`}
                        >
                          <option value="">Select delivery location</option>
                          {rateCards?.map((datum) => (
                            <option
                              key={datum?.id}
                              value={datum?.id.toString()}
                            >
                              {`${datum?.from_office} - ${datum?.delivery_location}`}
                            </option>
                          ))}
                        </select>

                        {errors?.deliveryLocations &&
                          errors?.deliveryLocations?.[index]?.rate_card_id && (
                            <small className="px-3 text-red-500">
                              {
                                errors?.deliveryLocations?.[index]?.rate_card_id
                                  .message
                              }
                            </small>
                          )}
                      </div>
                      {/* <InputSelect
                      label="Select Rate"
                      isRequired
                      choices={
                        rateCards?.map((datum) => {
                          return {
                            value: datum?.id.toString(),
                            label: `${datum?.from_office} - ${datum?.delivery_location}`,
                          }
                        }) || []
                      }
                      value={getValues(
                        `deliveryLocations.${index}.rate_card_id`
                      )}
                      name={`deliveryLocations.${index}.rate_card_id`}
                      setValue={handleSetValue}
                      placeholder="Select Rate"
                      error={
                        errors?.deliveryLocations?.[index]?.rate_card_id
                          ?.message
                      }
                    /> */}
                      {/* <div className="flex gap-2">
                      <Label label="Receiver address" isRequired />
                    </div> */}
                      {/* <AutocompleteSearch
                      state={watch(
                        `deliveryLocations.${index}.delivery_point_location`
                      )}
                      onSelectPlace={(details) =>
                        handleDestinationPlace(index, details)
                      }
                      placeholder="Search delivery location"
                      setState={(value) =>
                        setValue(
                          `deliveryLocations.${index}.delivery_point_location`,
                          value.toString()
                        )
                      }
                    /> */}
                      {/* {errors?.deliveryLocations &&
                      errors?.deliveryLocations?.[index]
                        ?.delivery_point_location && (
                        <small className="px-3 text-red-500">
                          {
                            errors?.deliveryLocations?.[index]
                              ?.delivery_point_location.message
                          }
                        </small>
                      )} */}
                    </div>
                    <div
                      className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}
                    >
                      <Input
                        {...register(
                          `deliveryLocations.${index}.delivery_point_email`
                        )}
                        label="Email Address"
                        placeholder="Enter email address"
                        error={
                          errors?.deliveryLocations?.[index]
                            ?.delivery_point_email?.message
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`flex ${
                      screenWidth >= 560 ? 'flex-row gap-8' : 'flex-col gap-3'
                    } items-center`}
                  >
                    <div
                      className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}
                    >
                      <Input
                        placeholder="enter delivery location"
                        label="Delivery Address"
                        isRequired
                        {...register(
                          `deliveryLocations.${index}.delivery_point_location`
                        )}
                        error={
                          errors?.deliveryLocations?.[index]
                            ?.delivery_point_location?.message
                        }
                      />
                    </div>
                    <div
                      className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}
                    >
                      <Input
                        {...register(
                          `deliveryLocations.${index}.destination_landmark`
                        )}
                        label="Landmark or B/stop"
                        placeholder="Enter b/stop (optional)"
                      />
                    </div>
                  </div>
                  <div
                    className={`flex ${
                      screenWidth >= 560 ? 'flex-row gap-8' : 'flex-col gap-3'
                    } items-center flex-row-reverse `}
                  >
                    <div
                      className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}
                    >
                      {(() => {
                        const selectedDeliveryType = watch(
                          `deliveryLocations.${index}.rate_card_id`
                        )
                        return selectedDeliveryType ? (
                          <div>
                            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                              Amount
                            </p>
                            <span
                              style={{ fontWeight: 'bold', fontSize: '25px' }}
                            >
                              {amountFormat(getPrice()[index].toString())}
                            </span>
                          </div>
                        ) : null
                      })()}
                    </div>

                    <div
                      className={`${screenWidth >= 560 ? 'w-[50%]' : 'w-full'}`}
                    >
                      <div className="flex gap-2">
                        <Label label="Delivery type" />
                      </div>
                      <div className="flex gap-8 items-center mt-3">
                        {deliveryType.map((del, idx) => {
                          const selectedDeliveryType = watch(
                            `deliveryLocations.${index}.delivery_type`
                          )
                          return (
                            <div className="relative" key={idx}>
                              <div
                                onClick={() => {
                                  setValue(
                                    `deliveryLocations.${index}.delivery_type`,
                                    del
                                  )
                                  onChargesClose()
                                }}
                                key={idx}
                                className="flex items-center gap-2 cursor-pointer"
                              >
                                <div
                                  className={`h-5 w-5 rounded-full flex-shrink-0 ${
                                    selectedDeliveryType === del
                                      ? 'border-2 border-brand-600 bg-brand-600'
                                      : 'bg-[#D9D9D9]'
                                  }`}
                                ></div>
                                <p className="text-gray-700 text-sm font-medium">
                                  {del}
                                </p>
                              </div>
                              <div className="bg-[#f0f0f0] p-2 rounded-md mt-2 z-10 w-fit">
                                <p className="text-sm text-gray-700 w-fit">
                                  {del === 'Corporate Delivery'
                                    ? 'Expect Delivery within 24 to 48 hours'
                                    : 'This is an express delivery type'}
                                </p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                {fields.length > 1 && (
                  <div
                    onClick={() => {
                      remove(index)
                      onChargesClose()
                    }}
                    className="flex items-center gap-4 cursor-pointer justify-end mt-5"
                  >
                    <p className="text-sm font-bold text-brand-600">
                      Remove Location
                    </p>
                    <LuMinusCircle className="text-brand-600" size={24} />
                  </div>
                )}
              </div>
            ))}
            <div className="my-5 flex items-center gap-4">
              {/* <PrimaryButton
              type="submit"
              text="Save"
              variant="primary"
              className="w-[140px]"
              // isLoading={isPending}
            /> */}
              <PrimaryButton
                type="reset"
                text="Reset"
                variant="primary"
                className="w-[140px] bg-brand-25"
                onClick={() => reset()}
              />
            </div>
          </div>

          <div className="min-835:w-[30%] w-full min-hscreen sticky top-0 left-0 min835:block hidde">
            <div className="px-8 h-screen overflow-y-scroll overflow-hidden">
              <PaymentMethod
                onSuccessOpen={onSuccessOpen}
                chargesVisibility={chargesVisibility}
                onChargesOpen={onChargesOpen}
                onChargesClose={onChargesClose}
                setCoupon={setCoupon}
                setPayeeType={setPayeeType}
                coupon={coupon}
                payeeType={payeeType}
                onReset={() => {
                  reset()
                }}
                isConfirm={isConfirm}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </form>
        {/* <div className="min-835:hidden block px-8">
          <PaymentMethod
            onSuccessOpen={onSuccessOpen}
            chargesVisibility={chargesVisibility}
            onChargesOpen={onChargesOpen}
            onChargesClose={onChargesClose}
            setCoupon={setCoupon}
            setPayeeType={setPayeeType}
            coupon={coupon}
            payeeType={payeeType}
            onReset={() => {
              reset()
            }}
            isConfirm={isConfirm}
            onSubmit={onSubmit}
          />
        </div> */}
      </>
    )
  }
)

export default Request
