import Divider from '@/components/Divider'
import Notifications from './components/notifications'
import RecentOrder from './components/recent-order'
import TrackingInput from './components/tracking-input'
import UserLocation from './components/user-location'
import Ads from '@/components/ads'
import SendPackage from '@/components/send-package'
import Seo from '@/components/Seo'
import PrimaryButton from '@/components/Button/PrimaryButton'
import { useDisclosure } from '@/hooks/useDisclosure'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useGetOrders } from '@/services/order'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import Modal from '@/components/Modal/Modal'
import Input from '@/components/Input/Input'

const PartpaymentSchema = z.object({
  amount: z.string({ required_error: 'Amount is required' }),
})

type TPartpayment = z.infer<typeof PartpaymentSchema>

const Dashboard = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const queryClient = useQueryClient()
  const { payPartPayment } = useGetOrders()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<TPartpayment>({
    mode: 'onBlur',
    resolver: zodResolver(PartpaymentSchema),
    reValidateMode: 'onChange',
  })

  const { mutate: partPaymentFn, isPending: isPartPaymentPending } =
    useMutation({
      mutationFn: payPartPayment,
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ['get_orders'] })
        const redirectUrl = response?.authorization_url
        if (redirectUrl) {
          window.location.href = redirectUrl
        }
      },
    })

  const onSubmit: SubmitHandler<TPartpayment> = async (data) => {
    partPaymentFn(data)
  }

  return (
    <>
      <div className="flex w-full min-hscreen">
        <Seo />
        <div className="border-r border-r-gray-200 min-835:w-[70%] w-full py-5 overflow-y-scroll h-screen px-8">
          <div className="flex flex-col min-625:flex-row min-625:items-center gap-4">
            <UserLocation />
            <div className="mr-auto min-625:ml-auto">
              <PrimaryButton
                variant="primary"
                text="Pay part"
                size="sm"
                onClick={() => onOpen()}
              />
            </div>
          </div>
          <TrackingInput />
          <div className="min-835:hidden block">
            <Ads />
          </div>
          <RecentOrder />
        </div>
        <div className="w-[30%] wfull min-hscreen sticky top-0 left-0 min-835:block hidden">
          <div className="h-screen overflow-y-scroll overflow-hidden">
            <Notifications />
            <div className="px-4">
              <Divider />
              <SendPackage />
              <Ads />
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <Modal
          onClose={() => {
            onClose()
          }}
        >
          <form
            className="mt-20 flex flex-col gap-2 w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              label="Enter amount to pay"
              placeholder="amount"
              {...register('amount')}
              error={errors.amount?.message}
            />

            <div className="mt-10">
              <PrimaryButton
                text="Log in"
                variant="primary"
                className="w-full"
                isLoading={isPartPaymentPending}
                disabled={isPartPaymentPending}
              />
            </div>
          </form>
        </Modal>
      )}
    </>
  )
}

export default Dashboard
