import { AiOutlineMail } from "react-icons/ai"; // Ant Design Icons
import {
  MdCheckCircle,
  MdPedalBike,
  MdDone,
  MdInfoOutline,
  MdInfo,
} from "react-icons/md";

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export const getStatus = (status: string) => {
  switch (status) {
    case "ASSIGNEDTORIDER":
      return "Assigned To Rider";
    case "PENDING":
      return "Pending";
    case "INTRANSIT":
      return "In-Transit";
    case "DELIVERED":
      return "Delivered";
    case "CANCELLED":
      return "Cancelled";
    case "ACCEPTED":
      return "Accepted";
    default:
      return "Unknown";
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "COMPLETED":
      return "#32D583";
    case "DELIVERED":
      return "#32D583";
    case "CANCELLED":
      return "#EB5757";
    case "ASSIGNEDTORIDER":
      return "#F2994A";
    case "INTRANSIT":
      return "#F2994A";
    case "PENDING":
      return "#F2994A";
    case "ACCEPTED":
      return "#F2994A";
    default:
      return "#000";
  }
};

export const amountFormat = (num: string) =>
  new Intl.NumberFormat("en-NG", { style: "currency", currency: "NGN" }).format(
    Number(num)
  );

export const getDeliveryStatusStyles = (message: string) => {
  if (message.includes("accepted")) {
    return {
      backgroundColor: "#ebf8ff",
      icon: MdCheckCircle,
      iconColor: "#0077b6",
    };
  } else if (message.includes("picked up")) {
    return {
      backgroundColor: "#ebf8ff",
      icon: MdPedalBike,
      iconColor: "#0077b6",
    };
  } else if (message.includes("delivered")) {
    return { backgroundColor: "#CFF7D3", icon: MdDone, iconColor: "green" };
  } else if (message.includes("New Order")) {
    return {
      backgroundColor: "#f2cf4c40",
      icon: MdInfoOutline,
      iconColor: "gold",
    };
  } else if (message.includes("New Message From")) {
    return {
      backgroundColor: "#ebf8ff",
      icon: AiOutlineMail,
      iconColor: "#0077b6",
    };
  } else {
    return {
      backgroundColor: "#ebf8ff",
      icon: MdInfo,
      iconColor: "#0077b6",
    };
  }
};

export const phoneNumberWoPlus = (countryCode: string, phoneNumber: string) => {
  return `${countryCode.replace("+", "")}${phoneNumber}`;
};

export const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371; // Radius of the earth in kilometers
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in kilometers
  return d * 1000; // Convert to meters
};

export const formattedDate = (dateStr: string) => {
  const date = new Date(dateStr?.replace(/\//g, "-"));

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(date);

  return formattedDate;
};

export const formatPhoneNum = (text: string) => {
  const formattedPhone = text.replace(/[^0-9]/g, "");
  return formattedPhone;
};

export const getLastDayOfNextMonthFromToday = (daysToAdd = 30) => {
  const today = new Date();
  today.setDate(today.getDate() + daysToAdd); // Add 30 days
  return new Date(today.getFullYear(), today.getMonth() + 1, 0);
};
