import Spinner from "@/components/Spinner";
import { APP_ROUTES } from "@/constants/app-routes";
import { useGetNotifications } from "@/services/notifications";
import { getDeliveryStatusStyles } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { CiBellOn } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const navigate = useNavigate();
  const { getNotifcations } = useGetNotifications();

  const { data: noty, isLoading } = useQuery({
    queryKey: ["get_notifications"],
    queryFn: () => getNotifcations(1),
    staleTime: 60000,
  });

  const data = noty?.data?.slice(0, 4);

  return (
    <div className="overflow-y-scroll px-4 h-[410px]">
      <div className="flex justify-between items-center">
        <h2 className="text-gray-900 text-lg font-bold">Notifications</h2>
        <p
          onClick={() => navigate(APP_ROUTES.NOTIFICATIONS)}
          className="text-brand-600 font-medium text-sm cursor-pointer"
        >
          View All
        </p>
      </div>

      {isLoading && <Spinner />}

      {data?.length === 0 && (
        <div className="mt-[60px] flex flex-col justify-center items-center">
          <div className="flex items-center justify-center w-20 h-20 bg-[#DFDFDF40] rounded-full">
            <CiBellOn size={32} />
          </div>
          <p className="text-gray-500 text-sm text-center mt-[30px]">
            Your Notifications will appear here
          </p>
        </div>
      )}

      {data && data?.length > 0 && (
        <div className="mt-4 flex flex-col gap-4">
          {data?.map((noty, idx: number) => {
            const {
              backgroundColor,
              icon: IconComponent,
              iconColor,
            } = getDeliveryStatusStyles(noty?.message);
            return (
              <div key={idx} className="mt4">
                {/* <p className="text-gray-500 text-[12px] font-medium">Today</p> */}
                <div className="flex gap-4 mt-3">
                  <div
                    className="flex items-center w-[42px] h-[42px] p-3 rounded-full relative"
                    style={{ backgroundColor }}
                  >
                    <IconComponent size={24} color={iconColor} />
                    {/* {noty?.status === "UNREAD" && (
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "red",
                          height: 10,
                          width: 10,
                          borderRadius: 9999,
                          top: 0,
                          right: 0,
                        }}
                      ></div>
                    )} */}
                  </div>
                  <div className="flex flex-col gap-3">
                    <p className="text-gray-800 text-sm">{noty?.message}</p>
                    <p className="text-gray-500 text-sm">
                      {noty?.message_body}
                      <p className="">{noty?.estimated_time}</p>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Notifications;
